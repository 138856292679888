.global-container {
    padding: 20px 10px 20px 210px;
}
@media screen and (max-width: 870px) {
    .global-container {
        padding:  0px;
        position: relative;
        width: 100%;
        top: 70px;
    }
}