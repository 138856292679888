.sidenav {
    position: fixed;
    left:0;
    top: 0;
    height: 100vh;
    background: #f1f1f1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 200px;
}
.sidenav a {
    padding: 10px 10px;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
    text-decoration: none;
    color: #333;
    font-size: 20px;
}
.sidenav a:hover {
    background: #abd5db;
}
.sidenav a:focus {
    background: #abd5db;
}
.sidenav img {
    width: 100%;
}

@media screen and (max-width: 870px) {
    .sidenav {
        position: absolute;
        left:0;
        top: 0;
        background: #f1f1f1;
        display: flex;
        justify-content: center;
        align-items: center;    
        flex-direction: row;
        height: 70px;
        width: 100%;
    }
    .sidenav a {
        padding: 10px 10px;
        width: auto;
        text-align: center;
        text-decoration: none;
        color: #333;
        font-size: 22px;
    }
    .sidenav img {
        display: none;
        width: 50px;
        margin: 20px 0px 20px;
    }
}